<template>
    <div class="addPayment-wrap">
        <van-nav-bar
            title="添加收付款方式"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="addPayment-main-wrap">
            <h3>选择收付款方式</h3>
            <div class="addPayment-type-box">
                <div class="item-box" :class="{'active' : paymentWayIndex == 1}" @click="handleChangeWay(1)">
                    <van-image :src="themeImgs.commonPaymentIcon1" />
                    <span>支付宝</span>
                </div>
                <div class="item-box" :class="{'active' : paymentWayIndex == 2}" @click="handleChangeWay(2)">
                    <van-image :src="themeImgs.commonPaymentIcon2" />
                    <span>微信</span>
                </div>
                <div class="item-box" :class="{'active' : paymentWayIndex == 3}" @click="handleChangeWay(3)">
                    <van-image :src="themeImgs.commonPaymentIcon3" />
                    <span>银行卡</span>
                </div>
            </div>
            <h3>填充信息</h3>
            <div class="alipay-info-wrap common-info-wrap" v-if="paymentWayIndex == 1">
                <div class="info-item">
                    <span class="label">姓名</span>
                    <van-field v-model="realname" :readonly="isFromComprehensive" placeholder="请输入真实姓名" autocomplete="off" />
                </div>
                <div class="info-item">
                    <span class="label">支付宝账号</span>
                    <van-field v-model="alipayAccount" placeholder="请输入支付宝账号" autocomplete="off" />
                </div>
                <div class="info-item">
                    <span class="label">上传收款码</span>
                    <van-uploader v-model="codeFileList" :max-count="1" :before-read="beforeUpload" :after-read="afterUpload" />
                </div>
            </div>
            <div class="wechat-info-wrap common-info-wrap" v-if="paymentWayIndex == 2">
                <div class="info-item">
                    <span class="label">姓名</span>
                    <van-field v-model="realname" :readonly="isFromComprehensive" placeholder="请输入真实姓名" autocomplete="off" />
                </div>
                <div class="info-item">
                    <span class="label">上传收款码</span>
                    <van-uploader v-model="codeFileList" :max-count="1" :before-read="beforeUpload" :after-read="afterUpload" />
                </div>
            </div>
            <div class="bank-info-wrap common-info-wrap" v-if="paymentWayIndex == 3">
                <div class="info-item">
                    <span class="label">姓名</span>
                    <van-field
                            v-model="realname"
                            :readonly="isFromComprehensive"
                            placeholder="请输入真实姓名"
                            autocomplete="off"
                            @blur="handleBlur('realname')"
                    />
                </div>
                <div class="info-item">
                    <span class="label">银行卡号</span>
                    <van-field
                            v-model="bankCard"
                            placeholder="请输入银行卡卡号"
                            maxlength="19"
                            autocomplete="off"
                            @blur="handleBlur('bankCard')"
                    />
                </div>
                <div class="info-item">
                    <span class="label">选择银行</span>
                    <div class="bank-box" @click="$router.replace('/search')">
                        <span>{{ bankName.name ? bankName.name : '请选择银行' }}</span>
                        <van-image :src="themeImgs.commonColorArrowDownIcon" />
                    </div>
                </div>
            </div>
            <div class="add-btn-box">
                <van-button block :disabled="btnClock" @click="handleAdd">添加</van-button>
            </div>
        </div>

<!--        <van-action-sheet-->
<!--            v-model:show="showBankAction"-->
<!--            :actions="bankActions"-->
<!--            @select="handleSelectBankAction"-->
<!--        >-->
<!--            <template #description>-->
<!--                <div class="searchBank">-->
<!--                    <van-field-->
<!--                            v-model="search"-->
<!--                            input-align="center"-->
<!--                            placeholder="请选择银行"-->
<!--                    />-->
<!--                    <van-button type="primary" @click="onConfirm">-->
<!--                        {{ search ? '完成' : '搜索' }}-->
<!--                    </van-button>-->
<!--                </div>-->
<!--            </template>-->
<!--        </van-action-sheet>-->

        <van-overlay class-name="addPayment-overlay" :show="showOverlay">
            <van-loading size="24px" type="spinner" color="#fff" vertical>上传中...</van-loading>
        </van-overlay>
    </div>
</template>

<script>
import { ref,onMounted,computed, watch, onBeforeMount } from 'vue'
import {getBankList,doAddPayment,uploadImage} from '@/api/api'
import { Toast } from 'vant'
import { useRouter, useRoute } from "vue-router"
import { useStore } from 'vuex'

export default {
    setup () {
        const route = useRoute()
        const $router = useRouter()

        const store = useStore()
        //  银行卡搜索
        // const search = ref('')
        //  保存银行卡完整数据
        // const searchResults = ref([])

        //  监听查询字段
        // watch(search, (newValue) => {
        //   const data = fuzzySearch(newValue)
        //   bankActions.value = data
        // })

        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
        // 默认付款方式索引值
        const paymentWayIndex = ref(1)
        // 选择收付款方式,并重置所有
        const handleChangeWay = (index) => {
            paymentWayIndex.value = index
            if(!window.parent.frames['agIframe']) {
                realname.value = ''
            }
            alipayAccount.value = ''
            codeFileList.value = []
            bankCard.value = ''
        }
        // 是否显示银行卡actions
        // const showBankAction = ref(false)
        // 银行卡actions
        // const bankActions = ref([]);
        // 获取银行列表数据
        // const handleGetBankList = () => {
        //     getBankList().then(res => {
        //         if(res.result == 200000) {
        //             Object.getOwnPropertyNames(res.data).forEach(function(key){
        //                 let obj = {}
        //                 obj.name = res.data[key]
        //                 obj.id = key
        //                 bankActions.value.push(obj)
        //                 searchResults.value.push(obj)
        //             })
        //         }
        //     })
        // }
        // 判断是否从综合那接收真实姓名
        const isFromComprehensive = ref(false)
        const handleJudgeFromComprehensive = () => {
            if(window.parent.frames['agIframe']) {
                if (store.state.actualName) {
                    realname.value = store.state.actualName
                    isFromComprehensive.value = true
                }
            } else {
                realname.value = ''
                isFromComprehensive.value = false
            }
        }
        // 真实姓名
        const realname = ref('')
        // 支付宝账号
        const alipayAccount = ref('')
        // 银行卡号
        const bankCard = ref('')
        // 银行名称
        const bankName = ref({
            name: '',
            id: ''
        })

        onMounted(() => {
            // handleGetBankList()
            handleJudgeFromComprehensive()

            if (route.query.hasOwnProperty('search')) {
              const bankData = JSON.parse(route.query.search)
              realname.value = localStorage.getItem('realname')
              bankCard.value = localStorage.getItem('bankCard')
              bankName.value = bankData
              paymentWayIndex.value = parseInt(route.query.currentIndex)
            }
        })

        // 选择银行
        // const handleSelectBankAction = (item) => {
        //     bankName.value = item
        //     // showBankAction.value = false
        // }

        // 只能输入中文
        const formatter = (value) => value.replace(/[^\u4e00-\u9fa5]/g, "");
        // 存储上传收款码得到的文件流
        const codeFileList = ref([])
        // 存储上传收款码得到的图片地址
        const codeUrl = ref('')
        // 上传收款码前
        const beforeUpload = () => {
            showOverlay.value = true
            codeFileList.value = []
            codeUrl.value = ''
            return true
        }
        // 上传收款码后
        const afterUpload = (file) => {
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            formData.append('file', file.file)
            formData.append('file_name', 'collection')
            uploadImage(formData).then(res => {
                if(res.result == 200000) {
                    codeUrl.value = res.data.path
                    file.status = 'success'
                    showOverlay.value = false
                } else {
                    file.status = 'failed';
                    file.message = '上传失败';
                    showOverlay.value = false
                    codeFileList.value = []
                    Toast.fail(res.msg)
                }
            })
        };
        // 是否显示蒙层
        const showOverlay = ref(false)
        // 添加收付款方式
        const handleAdd = () => {
            if (btnClock.value) return
            if(!realname.value) {
                return Toast.fail('请输入真实姓名')
            }
            if(paymentWayIndex.value == 1) {
                if(!alipayAccount.value) {
                    return Toast.fail('请输入支付宝账号')
                }
            }
            if(paymentWayIndex.value == 3) {
                if(!bankCard.value) {
                    return Toast.fail('请输入银行卡号')
                } else if(bankName.value.name == '请选择银行') {
                    return Toast.fail('请选择银行')
                }
            }
            btnClock.value = true
            let params = {
                type: paymentWayIndex.value,
                name: realname.value,
                card: "",
                bank_name: "",
                status: 1
            }
            if(paymentWayIndex.value == 1) {
                params.card = alipayAccount.value
            }
            if(paymentWayIndex.value == 3) {
                params.card = bankCard.value
                params.bank_name = bankName.value.id
            }
            if(paymentWayIndex.value != 3) {
                params.bank_name = codeUrl.value
            }
            doAddPayment(params).then(res => {
                btnClock.value = false
                if(res.result == 200000) {
                    Toast.success('添加成功')
                    $router.push('/payment')
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
        // 按钮锁
        const btnClock = ref(false)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)

        //  失焦时，保存用户名和银行卡账号
        const handleBlur = (type) => {
          switch (type) {
            case 'realname':
              localStorage.setItem('realname', realname.value)
              break
            case 'bankCard':
              localStorage.setItem('bankCard', bankCard.value)
              break
          }
        }
        return {
            onClickLeft,
            paymentWayIndex,
            handleChangeWay,
            // showBankAction,
            // bankActions,
            // handleSelectBankAction,
            realname,
            alipayAccount,
            bankCard,
            bankName,
            handleAdd,
            codeFileList,
            afterUpload,
            formatter,
            btnClock,
            themeImgs,
            showOverlay,
            beforeUpload,
            isFromComprehensive,
            handleBlur
        }
    }
}
</script>

<style lang="less" scoped>
.addPayment-wrap {
    .addPayment-main-wrap {
        height: calc(100vh - 46px);
        padding: 0 16px;
        background: var(--mainBgColor);
        h3 {
            padding: 20px 0 8px 0;
            font-weight: 500;
            font-size: 16px;
            color: var(--textCommonColor);
        }
        .addPayment-type-box {
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 50px;
            background: var(--boxBgColor);
            border-radius: 8px;
            .item-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90px;
                height: 38px;
                color: var(--textCommonColor);
                &.active {
                    background: rgba(255, 255, 255, 0.3);
                    border: 1px solid var(--textColor);
                    border-radius: 30px;
                    span {
                        color: var(--textColor);
                    }
                }
                .van-image {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }
        }
        .common-info-wrap {
            padding: 16px 12px;
            background: var(--boxBgColor);
            border-radius: 8px;
            .info-item {
                display: flex;
                align-items: center;
                padding: 8px 0;
                span {
                    font-size: 16px;
                    color: var(--textCommonColor);
                    &.label {
                        width: 100px;
                        font-weight: 500;
                    }
                }
                :deep(.van-field) {
                    flex: 1;
                    padding: 0;
                    background: inherit;
                    border-radius: 8px;
                }
                .bank-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    min-width: 184px;
                    padding: 2px 0 2px 10px;
                    background: rgba(255, 255, 255, 0.3);
                    border: 1px solid var(--textColor);
                    border-radius: 30px;
                    .van-image {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .add-btn-box {
            height: 44px;
            margin-top: 32px;
            border-radius: 8px;
            .van-button {
                border-radius: 8px;
            }
        }
    }
    .addPayment-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/deep/ .van-action-sheet__description {
    border-bottom: 5px solid #F3F4F7;
    position: sticky;
    top: 0;
}
/deep/ .van-action-sheet {
    height: 300px;
}

.searchBank {
    height: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    position: relative;
    .van-cell {
        background: transparent;
        position: absolute;
    }
    :deep {
        .van-button {
            background: transparent;
            padding: 0;
            color: #535C66;
            position: absolute;
            right: 15px;
        }
    }
}
</style>
