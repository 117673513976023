<template>
    <div class="payment-wrap">
        <van-nav-bar
            title="收付款"
            :border="false"
            fixed
        />
        <div class="payment-loading-wrap" v-if="showLoading">
            <van-loading size="48" color="#fff" vertical>加载中...</van-loading>
        </div>
        <div class="payment-main-wrap" v-if="!showLoading">
            <router-link to="/addPayment" class="add-payment-box" v-if="JSON.stringify(paymentList) == '{}'">
                <van-image :src="themeImgs.paymentAddIcon" />
                <span>添加收付款方式</span>
            </router-link>
            <div class="payment-item-wrap" v-else>
                <div v-for="(item,index) in paymentList" :key="index">
                    <div class="payment-item-box" v-if="item.type == 1">
                        <van-image class="type-img" :src="themeImgs.commonPaymentIcon1" />
                        <div class="payment-detail-wrap">
                            <div class="payment-detail-box">
                                <span class="label">支付宝名：</span>
                                <span class="text">{{item.name}}</span>
                            </div>
                            <div class="payment-detail-box">
                                <span class="label">支付宝账号：</span>
                                <span class="text">{{item.card}}</span>
                            </div>
                            <div class="payment-detail-box">
                                <span class="label">查看二维码：</span>
                                <span class="text" @click="handleOpenCodeDialog(item)">查看二维码<van-image class="type-img" :src="themeImgs.paymentDoublArrowIcon" /></span>
                            </div>
                        </div>
                        <div class="payment-status" :style="`background:url(${themeImgs.paymentStatusBgImg})`" v-if="item.status==2">禁用</div>
                        <div class="payment-status sys-disabled" :style="`background:url(${themeImgs.paymentStatusBgImg})`" v-if="item.status==3">系统禁用</div>
                    </div>
                    <div class="payment-item-box" v-if="item.type == 2">
                        <van-image class="type-img" :src="themeImgs.commonPaymentIcon2" />
                        <div class="payment-detail-wrap">
                            <div class="payment-detail-box">
                                <span class="label">微信名：</span>
                                <span class="text">{{item.name}}</span>
                            </div>
                            <div class="payment-detail-box">
                                <span class="label">查看二维码：</span>
                                <span class="text" @click="handleOpenCodeDialog(item)">查看二维码<van-image class="type-img" :src="themeImgs.paymentDoublArrowIcon" /></span>
                            </div>
                        </div>
                        <div class="payment-status" :style="`background:url(${themeImgs.paymentStatusBgImg})`" v-if="item.status==2">禁用</div>
                        <div class="payment-status sys-disabled" :style="`background:url(${themeImgs.paymentStatusBgImg})`" v-if="item.status==3">系统禁用</div>
                    </div>
                    <div class="payment-item-box" v-if="item.type == 3">
                        <van-image class="type-img" :src="themeImgs.commonPaymentIcon3" />
                        <div class="payment-detail-wrap">
                            <div class="payment-detail-box">
                                <span class="label">银行卡号：</span>
                                <span class="text">{{item.card}}</span>
                            </div>
                            <div class="payment-detail-box">
                                <span class="label">银行名：</span>
                                <span class="text">{{item.bank_name}}</span>
                            </div>
                        </div>
                        <div class="payment-status" :style="`background:url(${themeImgs.paymentStatusBgImg})`" v-if="item.status==2">禁用</div>
                        <div class="payment-status sys-disabled" :style="`background:url(${themeImgs.paymentStatusBgImg})`" v-if="item.status==3">系统禁用</div>
                    </div>
                </div>
                <div class="add-btn-box" v-if="Object.values(paymentList).length<3">
                    <van-button block to="/addPayment">
                        <span>添加收付款方式</span>
                        <van-image :src="themeImgs.paymentAddIcon" />
                    </van-button>
                </div>
            </div>
        </div>
        <!-- 二维码弹框 -->
        <van-dialog v-model:show="showCodeDialog" title="二维码" closeOnClickOverlay>
            <div class="code-box" :style="`background:url(${themeImgs.commonCodeBgImg})`">
                <van-image :src="dialogCodeImg" />
            </div>
        </van-dialog>
        <Footer />
    </div>
</template>

<script>
import { ref,onMounted,computed } from 'vue'
import Footer from "@/components/Footer"
import {getPaymentList} from '@/api/api'
import { useRouter } from "vue-router"
import { Toast } from 'vant'
import { useStore } from 'vuex'

export default {
    components: {
        Footer
    },
    setup () {
        const store = useStore()
        const $router = useRouter()
        // 是否显示加载
        const showLoading = ref(true)
        // 存储收付款列表数据
        const paymentList = ref({})
        // 获取收付款列表数据
        const handleGetPaymentList = () => {
            getPaymentList().then(res => {
                if(res.result == 1032) {
                    $router.push('/home')
                    return Toast.fail(res.msg)
                } else if(res.result == 200000) {
                    paymentList.value = res.data
                    showLoading.value = false
                } else {
                    showLoading.value = false
                }
            })
        }
        onMounted(() => {
            handleGetPaymentList()
        })
        // 是否显示二维码弹框
        const showCodeDialog = ref(false)
        // 存储二维码弹框所要展示的二维码
        const dialogCodeImg = ref('')
        // 打开二维码弹框
        const handleOpenCodeDialog = (item) => {
            showCodeDialog.value = true
            dialogCodeImg.value = item.bank_name
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            paymentList,
            showLoading,
            showCodeDialog,
            dialogCodeImg,
            handleOpenCodeDialog,
            themeImgs
        }
    }
}
</script>

<style lang="less" scoped>
.payment-wrap {
    .payment-loading-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 46px);
        padding: 24px 16px;
        margin-top: 46px;
        background: var(--mainBgColor);
    }
    .payment-main-wrap {
        position: fixed;
        top: 46px;
        bottom: 50px;
        width: 100%;
        padding: 24px 16px 0;
        background: var(--mainBgColor);
        overflow-y: scroll;
        .add-payment-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 143px;
            background: var(--btnBgColor);
            border-radius: 8px;
            .van-image {
                width: 72px;
                height: 72px;
                margin-bottom: 10px;
            }
            span {
                font-weight: 500;
                font-size: 18px;
                color: #fff;
            }
        }
        .payment-item-wrap {
            .payment-item-box {
                position: relative;
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                padding: 12px 18px;
                background: var(--boxBgColor);
                border-radius: 8px;
                .type-img {
                    width: 24px;
                    height: 24px;
                }
                .payment-detail-wrap {
                    margin-left: 14px;
                    .payment-detail-box {
                        display: flex;
                        align-items: center;
                        margin-bottom: 14px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        span {
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            color: var(--textCommonColor);
                            &.text {
                                color: var(--textColor);
                            }
                            .van-image {
                                width: 16px;
                                height: 16px;
                            }
                        }
                        
                    }
                }
                .payment-status {
                    position: absolute;
                    top: 11px;
                    right: 18px;
                    width: 44px;
                    height: 22px;
                    line-height: 22px;
                    background-size: 100% 100% !important;
                    color: #fff;
                    text-align: center;
                    &.sys-disabled {
                        width: 70px;
                    }
                }
            }
            .add-btn-box {
                height: 44px;
                margin-top: 20px;
                border-radius: 8px;
                :deep(.van-button) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    .van-button__text {
                        display: flex;
                        align-items: center;
                        span {
                            margin-right: 9px;
                            font-weight: 500;
                            font-size: 16px;
                        }
                        .van-image {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    
                }
            }
        }
    }
    @media only screen and (min-width: 750px) {
        .payment-main-wrap {
            width: 375px;
        }
    }
    :deep(.van-dialog) {
        .van-dialog__header {
            padding: 21px 0;
            font-weight: 500;
            font-size: 18px;
            color: var(--textColor1);
        }
        .code-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 275px;
            background-size: 100% 100% !important;
            .van-image {
                width: 188px;
                height: 188px;
                border-radius: 8px;
            }
        }
    }
}
</style>